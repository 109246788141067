@font-face {
  font-family: "CircularStd";
  src: url("assets/circular-std-font-family/CircularStd-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "CircularStd";
  src: url("assets/circular-std-font-family/CircularStd-Book.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CircularStd";
  src: url("assets/circular-std-font-family/CircularStd-Book.ttf")
    format("truetype");
  font-weight: light;
  font-style: normal;
}
.overflow-hidden {
  overflow: hidden;
}

.hero-section {
  background-color: #e8faf6;
 
  width: 100%;
  min-height: 100vh;
  background-image: url("assets/hero-background.png");
  background-repeat: no-repeat;
  background-position: bottom right;
  padding-bottom: 4em;
}

@media (min-width:2000px){
  .hero-section{
   min-height: auto;
   background-image: none;
  }

  
}


.custom-li-m {
  margin-right: 3.3em !important;
}
.container {
  font-family: "CircularStd", sans-serif;
}
nav {
  height: 7.6em;
}

.d-flex-velo,
.d-flex-velo-cycling {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

ul {
  align-self: center !important;
  margin-top: 0.8em !important;
}
ul li Link {
  margin: 0;
  padding: 0;
}
ul > li {
  margin-left: 2.7em;
}
nav > img {
  width: 160px;
  align-self: center;
}

.mobile-device-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mobile-device-header>img{
  width: 120px;
}
.mobile-device-header>button{
  border: unset;
  background-color: transparent;
}
.read-more-test-btn,
.btn-signup-velo,
.btn-insurance-add-velo,
.btn-velo,
.velo-key-btn,
.qoute-btn,
.buy-btn,
.cycling-btn {
  padding: 12px 26px;
  border-radius: 40px;
  font-weight: 600;
  background-color: white;
}
.btn-signup-container{
  display: flex;
  justify-content: flex-end;
}
.normal-link-typo,
.key-par-typo,
.buy-btn,
.velo-key-btn,
.qoute-btn,
.cycling-btn {
  font-size: 1.125rem;
  color: #374151;
}
.read-more-test-btn,
.btn-insurance-outline-velo,
.btn-outline-velo,
.velo-key-btn,
.cycling-btn {
  border: 2px solid #1acda7;
  color: #1acda7;
  background-color: white;
}
.btn-bg-signup-velo,
.btn-bg-velo,
.qoute-btn,
.buy-btn {
  background-color: #1acda7;
  color: white;
  border: #1acda7;
}
.partner-container {
  background-color: #f9fafb;
}
.same-btn-design {
  height: 40px;
  width: 40px;
}
.footer-paragraphs{
  margin-top: 20%;
}
.hero-large-typo
{
  font-size: 3.3rem;
  font-weight: 900;
  line-height: 82px;
  color: #09483a;
}
.qoute-title{
  font-size: 3rem;
  margin: 0;
  font-weight: 900;

}
.title-form-typo{
  font-size: 1.15rem;
  font-weight: 900;
}

.hero-quote-type,
.menu-footer-title,
.contact-footer-title {
  font-size: 1.5rem;
}
.hero-quote-type,.cover-title {
  color: #173537;
  /* text-shadow: 1px 1px 2px rgba(23, 53, 55, 0.1); */
}
.cover-title{
  font-size: 1.2rem;
}

.title-typo,
.title-cycling-typo,
.cover-detail-title-typo,.testimonial-title {
  font-size: 2.3rem;
  font-weight: 900;
  color: #215155;
}
.title-cycling-typo {
  width: 25%;
}
.testimonial-title{
  margin-bottom: 2em;
  text-align: center;
  font-family: "CircularStd", sans-serif;
}

.tiny-paragraph-typo,
.cycling-footer-description {
  font-size: 0.75rem;
  color: #374151;
  font-weight: 100;
}
.qoute-description {
  font-size: 1.75rem;
  width: 30%;
  font-weight: 100;
}


.ml-velo {
  margin-right: 1em;
}
.rounded-btn {
  border-radius: 50%;
}

.customer-logo > img {
  width: 50%;
  aspect-ratio: 2/2;
  object-fit: contain;
  mix-blend-mode: color-burn;
}

.weight-bold,
.menu-footer-title,
.contact-footer-title {
  font-weight: bold;
}

/* object-fit: cover; */
.small-dev-flex{
  display: grid;
  grid-template-columns: 1fr;
  grid-gap:2em ;
}

.velo-card {
  border-radius: 15px;
  border: 1px solid #b8f0e4;
  box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.5);
  padding: 1em;
  margin: 0.25em;
  height: auto;
  background-color: #ffffff;
  cursor: pointer;
}
.brand-velo-color {
  color: #1acda7;
}
.custom-velo-card {
  background-color: #e8faf6;
}
.selected-velo-card {
  color: #1acda7;
  background-color: #e8faf6;
}

.main-container-cover-section {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-auto-flow: dense;
  grid-gap: 2em;
  /* margin-top: -5em; */
  z-index: 99;
}

/* ===+++ */
.image-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 30.67%; /* This is the aspect ratio of your image (e.g. 3:2 = 66.67%) */
  overflow: hidden;
}

.image-caption {
  position: absolute;
  /* bottom: 0; */
  left: 5%;
  top: 15%;
  width: 100%;

  text-align: center;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  gap: 1em;
}

.qoute-title {
  cursor: pointer;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}



.main-container-cover-section-img,
.image-wrapper > img,
.overlay {
  object-fit: cover;
  border-radius: 15px;
}
.container-cover-section-text {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1em;
}

.bg-linear-color {
  background: linear-gradient(to left, #d3edef, #ffffff);
  height: 15vh;
}
.mt-velo {
  margin-top: 3em;
}
.cycling-arrow {
  height: 3.5em;
  width: 3.5em;
}
.cycling-arrow > i {
  font-size: 1.5rem;
}

.key-feature-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 1.5em;
  position: relative;
}
.key-feature-content-left .image-wrapper {
  position: relative;
}
.key-feature-content-left .image-wrapper .overlay,
.qoute-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: linear-gradient(
    to left,
    rgba(20, 154, 125, 0.1) 0%,
    rgba(20, 154, 125, 0.6) 60%,
    rgba(20, 154, 125, 0.9) 100%
  );
  opacity: 0.5;
}

.overlay-containers,
.card-container,
.velo-card-key,
.key-feature-content-right,
.velo-card-content,
.left-footer-container,
.center-footer-container,
.right-footer-container {
  display: flex;
  justify-content: space-between;
}
.left-footer-container,
.center-footer-container,
.right-footer-container{
  border-bottom: 2px solid #6b7280;
  padding-bottom: 1.5em;
}

.left-footer-container {
  justify-content: unset;
}
.overlay-containers {
  position: absolute;
  top: 0;

  grid-gap: 20px;
  width: 100%;

  padding: 2em;
}

.card-container,
.velo-card-key {
  align-items: left;
  gap: 1em;
}
.velo-card-key {
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.8); /* Use a lighter shade of white */
  border: 2px solid white;
  padding: .8em;

  max-width: 250px;
  box-shadow: 0px 0px 5px rgba(184, 240, 228, 0.711);
  /* Increase the opacity to make it lighter */
  opacity: 0.8;
}

.velo-card-key > img {
  width: 70px;
  padding: 0.8em;
  border-radius: 30%;
  background-color: white;
  border: 2px solid #1acda7;
  align-content: center;
}

.overlay-containers > img {
  align-self: flex-start;
}
.velo-card-key > p {
  color: #09483a;
  font-weight: 900;
}
.key-feature-content-right,
.card-container,
.velo-card-key,
.left-footer-container,
.center-footer-container,
.right-footer-container,
.contact-container {
  flex-direction: column;
}
.contact-container{
  display: flex;
  justify-content: space-between;
}
.velo-card-keyFeatures > img {
  width: 120px;
}
.title-icon > img {
  width: 30px;
}
.velo-card-keyFeatures {
  padding: 1.5em;
  border-radius: 15px;
}
.first-velo-card {
  background-color: #f6ffeb;
  border: 2px solid #7dbd2c;
}

.second-velo-card {
  background-color: #ebf1f2;
  border: 2px solid #ebf1f2;
}
.velo-card-content {
  gap: 2em;
}
.velo-key-btn {
  align-self: flex-end;
}
.velo-card-content-container {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  gap: .4em;
}
.title-icon {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
.title-icon > p {
  align-self: center;
}

.qoute-overlay {
  border-radius: 30px;
}


.cycling-news {
  background-color: #f9fafb;
}

.cycling-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.card-cycling {
  margin: .5em;
  border-radius: 30px;
  background-color: #ebf1f2;
}
.cycling-footer-content {
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;
  padding: 1em;
  gap: 1em;
}
.cycling-footer-content > p {
  color: #173537;
  font-size: 1.3rem;
  font-weight: 900;
}

.footer-container {
  background-color: #e8faf6;
}
.left-footer-container,
.center-footer-container,
.right-footer-container {
  gap: 1em;
}

.footer-main-container,
.footer-bottom-container {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-gap: 7em;
}

.footer-details {
  display: flex;
  gap: 1.5em;
  align-items: center;
  padding-top: 1em;
 
  width: 100%;
}

.footer-details > p {
  font-style: italic;
  font-size: 0.87rem;
}

.contact-icons {
  display: flex;
  gap: 1em;
}
.contact-icons > span {
  padding: 1em;
  border: 2px solid #1acda7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2em;
  width: 2em;
}
.contact-icons > span > i {
  color: #1acda7;
}
.contact-content > p,
.menu-container > p,
.footer-paragraphs > p,
.footer-details > p {
  color: #374151;
  font-weight: 100;
}
.footer-paragraphs>p{
  font-size: 1rem;

}


.cover-detail-position {
  position: absolute;
  top: 5%;
  left: 10%;
}

.footer-paragraphs > p,
.menu-container > p,
.contact-content > p {
  color: #4b5563;
  font-weight: 100;
}
.menu-container > p,
.contact-content > p {
  font-size: 1.2rem;
  margin: auto;
  line-height: 45px;
  font-weight: 100;
}

.small-nav {
  display: none;
}

.mobile-typo {
  display: none;
}
.buy-btn {
  position: absolute;
  top: 89%;
  left: 4%;
  z-index: 99;
}

.logo-small-device {
  display: none;
}
.star-container-col2 svg{
  height: 2em;
  width: 2em;

}


@media (max-width: 1403px) {
  .key-feature-container {
    grid-template-columns: 1fr;
  }
  .key-feature-content-right {
    gap: 1.5em;
  }
  .cycling-footer-content > p {
    font-size: 1rem;
  }
  .title-cycling-typo {
    font-size: 2rem;
  }
  .qoute-title{
    font-size: 2.5rem;
  }
  .qoute-description{
    font-size: 1.3rem;
  }
  .cycling-btn{
    font-size: .7rem;
    font-weight: 900;
  }
  
  
}

@media (max-width: 1200px) {
  .testimonial-title{
    margin-bottom: 0em;
  }
  .bike-insurance-header-headline{
    width: 100%;
  }
  .bike-insurance-header{
    flex-direction: column;
    

  }
  .bikeInsurance-navigate-form-btn{
    align-self: flex-end;
    margin-top: 1em;
  }

  .bike-insurance-footer{
    display: grid;
    grid-template-columns:1fr !important;
    grid-gap: 1em;
  }
  .bike-insurance-footer{
    align-self: start;
  }
  .cycling-footer-content > p {
    font-size: 0.9rem;
  }
 
  .title-cycling-typo {
    width: 50%;
  }
  .image-container {
    padding-bottom: 30.67%; /* This is the aspect ratio of your image (e.g. 3:2 = 66.67%) */
  }
  .image-caption {
    top: 10%;
    gap: 0.4em;
  }
  .row-1 .col-1,.row-1 .col-2,.row-3 .col-1,.row-3 .col-2,.row-2 .col-1, .row-2 .col-3{
    display: none;
  }
  .row-2{
    width: fit-content !important;

  
  }
  .col-1, .col-2 ,.col-3{
    width: unset;
  }
  .row-2 .col-2{
    width: 100% !important;
  }
  .row-3{
    width: 100%;
  }
  .my-slider{
    margin: auto;
  }
  .btns-testi-container{

    width: 100% !important;
    margin: 0 auto;
  }
  
  #parent-slider-container-testimonial{
    width: 600px !important;
  }
  .parent-container{
    margin-top: 2em;
    max-height: 100vh;
  }
  .testimonial-read-more-btn-container{
    margin-top: 2em;
  }
 
  .testimonial-read-more-btn-container{
    margin: 0em auto !important;
  
  }
 
}

@media (max-width: 991px) {
  .bike-insurance-content, .bike-insuranceHeader,.bike-insurance-header-content{
    width: 100% !important;
  }

  .hero-section{
    min-height: auto; 
    height: unset;
    /* height: calc(100vh - 100px);  */
    padding-bottom: 2em ;
  }
  .bike-insuranceHeader{
    background-image: none !important;
  }

  
  .btn-velo,
  .velo-key-btn,
  .qoute-btn,
  .buy-btn,
  .cycling-btn {
    padding: 13px 24px;
  }
  .normal-link-typo,
  .key-par-typo,
  .buy-btn,
  .velo-key-btn,
  .cycling-btn {
    font-size: 0.875rem;
  }
  .btn-velo {
    font-size: 0.78rem;
  }
  .image-caption {
    top: 0%;
    gap: 0em;
  }
  .qoute-title {
    font-size: 2.3rem;
  }

  /* small navigation start here  */

  nav > img {
    width: 130px;
  }
  /* small navigation end here */

  .hero-large-typo {
    font-size: 2rem;
    line-height: 3.75rem;
  }
  .hero-quote-type {
    font-size: 0.8rem;
  }

  

  .title-typo {
    font-size: 2rem;
  }
  .overlay-containers>img{
    width: 120px;
  }
  .overlay-containers {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 3em;
  }

  .velo-card-key {
    width: 100%;
    flex-direction: row;
 
  }
.image-caption{
  top: 10%;
}
  .qoute-title{
    font-size: 2rem;
  }
  .qoute-description{
    font-size: 1rem;
  }
  .footer-main-container{
    gap: 1.5em;
  }
  .footer-paragraphs>p{
    font-size: .7rem;

  }
   .menu-container>p, .contact-content>p{
    font-size: .9rem;
    line-height: 30px;

  }

  .main-container-cover-section {
    grid-template-columns: 1fr;
  }

  .cover-img {
    margin: 0 auto;
  }
  .cover-img > img {
    height: unset;
    grid-column: 1 / span 1;
    max-width: 400px;
    max-height: 600px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
 
 
}

@media (max-width: 768px) {
  .bikeInsuranceQuteImg>img{
      object-fit:fill !important;
      border-top-right-radius:10px ;
      border-bottom-right-radius:10px ;
     
    
    }
    /* .parent-container{
    max-height: 110vh;
    } */

  .bike-insurance-header-headline{
    gap: unset;
    padding: unset;
  }
  .dash-nav{
    width: 60px;
  }
  .dot-nav{
    width: 5px !important;
    height: 5px !important;
  }
  .hero-section{
    min-height: 100vh;
    padding-bottom: 3em;
  }
  .btn-velo,
  .velo-key-btn,
  .qoute-btn,
  .buy-btn,
  .cycling-btn {
    padding: 12px 20px;
  }
 

  .normal-link-typo,
  .key-par-typo,
  .velo-key-btn,
  .cycling-btn {
    font-size: 0.775rem;
  }
  .btn-velo{
    font-size: .8rem;
  }

  
  .hero-container-detail {
    flex-direction: column-reverse !important;
   
    
    gap: 0.7em;
  }
 
  nav > img {
    width: 100px;
  }
  ul > li {
    margin-left: 1.4em;
  }
  .hero-large-typo {
    font-size: 2rem;
    line-height: 1.5em;
    font-weight: 900;
  }
  .hero-quote-type {
    font-size: 1rem;
    line-height: 2em;
  }

  .customer-logo > img {
    width: 120px !important;
  }

  .cover-img {
    display: none;
  }
  .container-cover-section-text {
    grid-template-columns: 1fr;
  }
  .cover-detail-title-typo {
    position: unset;

    font-size: 1.5rem;
  }

  .header-cover-toggle {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  .container-cover-section-text i {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e8faf6;
    color: #1acda7;
    border-radius: 50%;
    cursor: pointer;
  }

  .fa-chevron-down{
    top: 55% !important;
  }


  .mobile-typo {
    display: block !important;
    font-family: "CircularStd", sans-serif;
    font-weight: 900;
    font-size: 1.4rem;
    color: #173537;
  }
  .card-container{
    display: grid;
    grid-template-columns: 1fr;

  }
  .velo-card-key {
    padding: 0.2em;
    display: flex;
    justify-content: flex-start !important;
    align-items: center !important;
    min-width: 100% !important;

  }
  .velo-card-key > img {
    width: 50px;
  }
  .velo-card-key > p {
    font-size: 0.7rem;
  }
  .overlay-containers > img {
    width: 90px;
  }


  .cycling-footer-content {
    gap: 0.6em;
    display: unset;
    display: grid;
    grid-template-columns: 2fr 1fr;
  }

  .cycling-footer-content > p {
    font-size: 0.67rem;
  }
  .footer-main-container,
  .footer-bottom-container {
    grid-template-columns: 1fr;
    grid-gap: 3em;
  }
  .footer-bottom-container {
    border-top: 1px solid #6b7280;
    padding-top: 2em;
    gap: 0em;
  }
  .footer-details {
    gap: 0em;
    display: unset;
    padding-top: unset;
    border-top: unset;
  }

  .center-footer-container {
    order: -3;
  }
  .right-footer-container {
    order: -2;
  }

  .logo-small-device {
    display: block;
    padding-bottom: 2.5em;
    width: 120px;
  }
  .logo-web-device {
    display: none;
  }

  .left-footer-container,
  .center-footer-container,
  .right-footer-container {
    gap: 0.3em;
    border-bottom: unset;
  }

  .velo-card-content{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .buy-btn{
    top: 82%;
    left: 7%;
  }
  .image-container {
    padding-bottom: 40.67%; /* This is the aspect ratio of your image (e.g. 3:2 = 66.67%) */
  }
  .footer-paragraphs{
    margin-top: 0%;
  }

  .menu-footer-title, .contact-footer-title{
    font-size: 1rem;
  }
  .footer-main-container{
    gap: 1em;
  }

  

  .contact-icons >span {
    padding: .2em !important;
    
  }

  .footer-details > p {
    font-style: italic;
    font-size: 0.67rem;
    line-height: 10px;
  }
   #parent-slider-container-testimonial{
   max-width: 450px !important;
  }
  .star-container-col2 svg{
    height: 1.5em !important;
    width: 1.5em !important;
  
  }
  .testimonial-title{
    font-size: 2rem;
  }
  .par-title-testimonal{
    font-size: 1.6rem !important;
  }
  .testimonial-read-more-btn-container{
    margin-top: .5em;
  }
  .bikeInsuranceQuteImg>img{
    display: none;
  }
  .half-left-bike-header{
    flex-basis: 100% !important;
  }

 

  
  
}

@media (max-width: 534px) {
  .bike-insuranceHeader{
    border-radius: 10px !important;
  }
  .bike-insurance-header-img>img{
    /* border: 2px solid red; */
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }
  
  .bike-insurance-content{
    width: 100% !important;
    border-radius: 10px !important;
  }
  .bike-insuranceHeader{
    width: 100% !important;

  }

 

  ul li {
    display: none;
  }
  .small-nav {
    display: flex;
  }
  .small-nav > a {
    color: #1acda7;
    padding-right: 1.5em;
    border-right: 1px solid #09483a;
  }
  .hamburger-icon {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }

  .fa-bars {
    color: #4b5563;
    font-size: 24px;
    padding-left: 1.5em;
  }


  .btn-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
 
  .customer-logo > img {
    max-width: 100px !important;
  }
  .title-typo,
  .title-cycling-typo {
    width: 200px;
    font-size: 1.5rem;
  }
  .card-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.3em;
  }
  .velo-card-key {
    min-width: 100% !important;
      transform: translatey(-5px);
  }
  .key-feature-container {
    gap: 1em;
  }
  .key-feature-content-right {
    gap: 1em;
  }


  .overlay-containers{
    gap: 2em;
  }
  .velo-card>*{
    padding: unset;
  }
  .velo-card-key>p{
    font-weight: 900;
    font-size: .8rem;
    /* width: 40% !important; */
  }
  .card-container{
    gap: .3em;
  }
  .image-container {
    padding-bottom: 52.67%; /* This is the aspect ratio of your image (e.g. 3:2 = 66.67%) */
  }
  .qoute-title{
    font-size: 2rem;
    line-height: 60px;
  }
  .qoute-description{
    font-size: .8rem;
  }
  
  .cycling-footer-content{
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 1em;
  }
  .cycling-container,.card-cycling{
    margin: .1em;
  }
  .card-cycling>img{
    border-radius: 15px;
  }


  .cycling-footer-content>p{
    font-size: 1.2rem;
  
  }
 

  .par-desc-testimonal{
    font-size: 1rem !important;
  }

  .star-container-col2 svg{
    height: 1em !important;
    width: 1em !important;
  
  }
  #parent-slider-container-testimonial{
    width: 350px !important;
   }
   .testimonial-read-more-btn-container>.read-more-test-btn{
    width: 100%;
   }

  


  
}

@media (max-width: 437px) {
  .bike-insurance-header-img>img{
    display: none;
  }
  .bike-insurance-header-textial{
    flex-basis: 100% !important;
  }


  .row-2 .col-2{
    padding-top: 3.5em !important;
    padding-left: 0em !important;
    padding-right: 0em !important;
    padding-bottom: 1em !important;

  }
  .par-title-testimonal{
    font-size: 1rem !important;

  }

  .par-desc-testimonal{
    font-size: .7rem !important;
  }
  #parent-slider-container-testimonial{
    max-width: 250px !important;
   }
  
 
  .qoute-description{
    width: 50%;
  }
  .image-container {
    padding-bottom: 65.67%;
  }

  
  .card-container > img {
    width: 40px;
  }
  .overlay-containers {
    gap: 0.3em;
    padding: 1em;
  }
  .velo-card-key{
    padding: .1em;
  }
  .velo-card-key > img {
    width: 40px;
    object-fit: cover;
  }

  .buy-btn {
    position: unset;
    position: absolute;
    /* top: 87%; */
    font-size: 0.7rem;
   
    min-width: 86%;
  }
  .title-icon {
    gap: 1em;
  }
  .card-container{
    margin-top: 1em;
  }

  .testimonial-read-more-btn-container{
    width: 80%;
    margin: auto;
    margin-top: 1.5em;
  }
  .testimonial-read-more-btn-container>.read-more-test-btn{
    padding: .4em ;
  }
 
}

@media (max-width:370px){
  #parent-slider-container-testimonial{
    max-width: 200px !important;
   }
  
}


.main-container-cover-section-img {
  width: 100%;
  height: 100%;
}

.velo-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: top;
}

.toggle-content-bottom {
  display: flex;
  align-items: flex-end;
  gap: 1em;
  transition: all .1s;
}

.display-none-container {
  display: none;
}

.container-cover-section-text {
  transition: all 0.2s;
}
.velo-card-close {
  /* max-height: 200px; */
  overflow: hidden;
  transition: all .2s ease-in-out;
}
.velo-card-open {
  opacity: 1;
  /* max-height: 1000px; */
  transition: all 0.2s ease-out;
}

.image-caption h2,
.image-caption p {
  color: #ffffff;
}



.mobile-device-nav {
  display: none;
}

.mobile-device-nav-block {
  background-color: #fff;
  display: block;
  width: 100%;
  position: absolute;
  top: 5%;
  margin: 0;
  padding: .5rem;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.mobile-device-nav-block .mobile-device-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ddd;
}

.mobile-device-nav-block .mobile-device-header img {
  width: 120px;
}

.mobile-device-nav-block .mobile-device-content {
  padding-left: 1rem;
}


.mobile-device-nav-block p {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
}


.close-icon {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #6B7280;
}

.close-icon:hover {
  color: #000;
}

@media only screen and (min-width: 534px) {
  .mobile-device-nav-block {
    display: none;
  }
 
}


ul.small-dev-flex{
  margin: 0;
  padding: 0;
}

.link-bike-insurance{
  color: #149A7D;
}
.bike-insurance-container{
  color: #09483A !important;
}

/* testimonials goes here */
.testimonial-container{

  background-color:#F2FCFA;
  padding-bottom: 3em;
}


.parent-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100vh;
}

.row-1 {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: auto;
}

.col-1, .col-2 {
  width: 50%;
}

.row-2 {
  display: flex;
  flex-direction: row;
  width: 100% ;
  justify-content: space-between !important;
  margin: auto;

}

.col-1, .col-2, .col-3 {
  width: 33.33%;
  border: 1px solid #C7E8EB;

}

.row-3 {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin:2em auto;
  justify-content: space-between;
}

.row-1>.col-1, .row-1>.col-2 {
  width: 50%;
}

.row-1,.row-2,.row-3{
  justify-content: space-between;
}



.col-1, .col-2, .col-3{
  background-color: white;
  max-width: 265px !important;
  border-radius: 20px;
}

.row-1>*{
  width: 50% !important;
}
.col-1,.col-2,.col-3{
  padding: 1em;
  display: grid;
  

}

.row-1 p , .row-2 p , .row-3 p{
  background-color: #E8FAF6;
  padding: .4em;
  border-radius: 5px;
  margin: .5em;
}

.row-1>.col-1,.row-1>.col-2,.row-2>.col-1,.row-2 .col-2,.row-2>.col-3,.row-3>.col-1,.row-3>.col-2{
  position: relative;
}

.row-1>.col-1>img,.row-1>.col-2>img,.row-2>.col-1>img,.row-2 .col-2 img,.row-2>.col-3>img,.row-3>.col-1>img,.row-3>.col-2>img{
  position: absolute;
  top: -40%;
  max-width: 80px;
  max-height: 80px;

}


.row-2>*{
  width: 33.33% !important;
  height: auto;
}
.row-3>*{
  width: 50% !important;
}
.row-1>.col-1,.row-1>.col-2, .row-2>.col-1,.row-2>.col-3 , .row-3>.col-1,.row-3>.col-2{
  max-height: 120px !important; 
}
.row-1>.col-1>img {
  left: 75%;
}
.row-1>.col-2>img , .row-2>.col-3>img{
  left: 0%;
}


.row-2{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.row-2 .col-2 p{
  background-color:unset;
  padding: unset;
}

.star-container-col2{
  display: flex;
  gap: .5em;
  width: fit-content;
  margin: 1em auto;
  margin-top: 2em;


}
.star-container-col2 span{
 
  display: grid;
  place-items: center;
}
.star-container-col2 svg{
  width: 50px;
  height: 30px;
}
.row-2 .col-2{
  padding:3em;
  
  max-width: 600px !important;
  border-radius: 40px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  z-index:-1;
  margin-top: 3.5em;
  display: flex !important;
  flex-direction: column;
 
}
.parent-slider-container-testimonial{
  width: 490px !important;
  
}

#testimonial-avatar{
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translate(-50%, -50%);
  max-width: 200px;
  max-height: 200px;
  z-index: 999;
}

.par-title-testimonal{
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  color: #215155;
}
.par-desc-testimonal{
  font-size: 1.3rem;
  font-weight: 100;
  text-align: center;
  color: #215155;
  /* font-weight: lighter; */
}
.btns-testi-container{
  display: flex;
  justify-content: center;
  width: fit-content;
  margin-top: 1em;
}
.col-1>p:nth-child(1), .row-1>.col-2>p:nth-child(1), .row-2>.col-3>p:nth-child(1){
  width: 65%;
  margin-left: 32%;
  
}
.row-1>.col-1>p:nth-child(1){
  margin-left: .5em !important;
}
.row-1 .col-2>p:nth-child(1),.row-2 .col-3>p:nth-child(1),.row-3 .col-2>p:nth-child(1){
  width: 65%;
  
}


/* bike insurance quote start */

.bike-insurance-content{
  width: 70%;
  margin: 0 auto;
  background-color: #EDF8F8;
  padding: 2em;
  border-radius: 30px;
}

.phone-input{
  border: 1px solid #B8F0E4;
  display: flex;
  border-radius: 10px;
  padding: .3em;
  background-color: white;
}

.phone-input>select,.phone-input>input{
  padding: .2em;
  border: none !important;
  outline: none !important;
  

  /* font-size: .8rem; */
}
.form-group-inputs>input,.date-picker-input,.serial-no-input,.address-1-container>textarea, .address-2-container>textarea{
  border: 2px solid #B8F0E4;
  border-radius: 10px;

  padding: .5em;



}
.phone-input{
  border: 2px solid #B8F0E4;
}

.form-group-inputs>input:hover,.date-picker-input:hover,.serial-no-input:hover, .phone-input:hover,.address-1-container>textarea:hover, .address-2-container>textarea:hover{
  border: 2px solid #1ACDA7;
 
}
.date-picker-input:focus,.serial-no-input:focus,.address-1-container>textarea:focus, .address-2-container>textarea:focus{
  outline: none;

}


.date-picker-input{
  width: 100%;

}

.serial-no-container{
  width: 100%;
}
.serial-no-input{
  width: 100%;
}
.footer-content-insurance{
  display: grid;
  grid-template-columns: 2fr 1fr;
}
.signUp-btn-container{
  align-self: end;
  text-align: right;
}

.bike-headline-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.remove-cycle-btn {
  border: none;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 68, 0, 0.801);
  color: white;
  display: grid;
  place-items: center;
}
.remove-cycle-btn i{
  font-size: 1rem;
}
.bike-insurance-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bike-insurance-header-headline{
  display: flex;
  gap: 1em;
  align-items: center;
}
.dot-nav{
  display: inline-block;
  width: 15px;
  height:15px;
  border-radius: 50%;
  background-color: #C7E8EB;

}
.dot-curent{
  border: 4px solid #1ACDA7;
  background-color: white;
}
.dash-nav{
  width: 70px;
  height:5px;
  background-color: #C7E8EB;

}
.dash-nav-current{
  background-color: #1ACDA7;
}
.container-visibility{
  display: none;
}

.bike-insurance-header-content{
  width: 70% ;
  background-color: #149A7D;
  border-radius: 30px;
  color: white;
  margin: auto;
  display: flex;
  
  
}
.half-left-bike-header{
  padding: 1.5em;
  flex-basis: 50%;
}
.bikeInsuranceQuteImg{
  flex-basis: 50%;

}
.bikeInsuranceQuteImg>img{
width:max-content;
  object-fit:fill;
  height: auto;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;

}
.half-left-bike-header>p{
  font-size: 2.25rem;
  font-weight: 900;
}
.half-left-bottom{
  background-color: white;
  color:#149A7D;
  padding: .6em .5em;
  border-radius: 10px;
}
.light-weight{
  font-weight: 100;
}
.bold-weight{
  font-weight: 900;
}

.half-left-bike-header p{
  margin: 0;
}
.row-1>.col-2>img,.row-2>.col-3>img,.row-3>.col-2>img{
  right: 0;
}

.address-1-container>textarea, .address-2-container>textarea{

  width: 100%;
  resize: none;

}

.bike-insurance-footer{
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 6em;



}

.bike-insurance-footer>button{
  align-self: end;
}




.bike-insuranceHeader{
  width: 70% ;
  
  display: flex;
  background-color: #149A7D;
  border-radius: 30px;
  margin: 0 auto;
  color: white;
  
}
.bike-insurance-header-textial{
  flex-basis:50%;
  height: auto;
  padding: 3em;
}
.bike-insurance-header-img{
  flex-basis: 50%;
  height: auto;

  
}
.bike-insurance-header-img>img{
  width: 100%;
  object-fit:fill;
  height: auto;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.bike-insuranceHeader h2{
  font-size: 2.25rem;
  font-weight: 900;
  margin: 0;
}

.insurance-headline-title >p{
  font-size: 1.5rem;
  font-weight: 900;
  font-family: "CircularStd", sans-serif;
}

.testimonial-read-more-btn-container{
  display: block;
  text-align: center;
  margin: 0 ;
  margin-top: -2.5em;
}



/* form design start here ===+++++++====
==+===++++++======*/



.paragraph-with-icon,.paragraph-with-icon-child{
  display: flex;
}

.paragraph-with-icon::before ,.paragraph-with-icon-child::before {
  content: "—";
  color: #333;
  margin-right: 0.5em;
 
}
.paragraph-with-icon-child::before{
  content: '-';
}
.paragraph-with-icon-indent{
  margin-left: 1em;
}

.dash-half-bg-color{
  background-color: unset;
  background-image: linear-gradient(to right, red 50%, transparent 50%);
  
}

.larger-bicycle-insurance-typo{
  font-size: 2rem;
  text-align: center;
  color: #149A7D;
  margin-top: 1em;
}
.bicycle-insurance-box{
  background-color: #149A7D;
  padding: 1em;
  border-radius: 20px;
  width: fit-content;
  text-align: center;
}
.bicycle-insurance-box>p:nth-child(1){
  font-size: 3rem;
  font-weight: 900;
  color: #ffffff;
  margin: 0;
}
.bicycle-insurance-box>p:nth-child(2){
  font-size: 1.7rem;
  font-weight: 700;
  color: #ffffff;
  margin: 0;
}
.bicycle-insurance-box>p:nth-child(3){
  font-size: 1.5rem;
  font-weight: 900;
  margin: 0;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.validation-border{
  border: 2px solid red;
}


@media (max-width:437px){
  .footer-content-insurance{
    grid-template-columns: 1fr;
  }
  .btn-signup-velo{
    width: 100%;
  }

 
  .bike-insurance-price{
    grid-template-columns: 1fr;
  }


}

.bike-insurance-price{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
}

@media  (min-width: 480px) and (max-width: 767px) {
  .parent-container{
    max-height: 140vh;
  }

}


@media (max-width:534px){
  .cycling-btn{
    width: fit-content;
    justify-self: flex-end;
  
  }
  .cycling-btn,.qoute-btn ,.cycling-arrow ,.read-more-test-btn ,.buy-btn ,.velo-key-btn ,.btn-velo
  ,.btn-velo{
    font-size: 14px !important;
    /* justify-self: end;
     */
  }

}

/* form design end here ===+++++++====
==+===++++++======*/
